.custom-table-filter {
  position: relative;
  display: inline-block;

  .filter-badge {
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: rgba(255, 165, 0, 1);
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.4rem;
    border-radius: 50%; /* Makes it circular */
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
}
