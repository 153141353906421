.supplier-details-page{
    .details-content-wrapper{
        border-radius: 7px;
        background-color: #fff;
        box-shadow: 0px 0px 4px 0px #4F666E40;
    }

    .contains-custom-accordion{
        .accordion-item{
            margin-bottom: 16px;
            border: none;
            border-radius: 8px !important;
            overflow: hidden;
            box-shadow: 0px 4px 4px 0px #00000040;
            position: relative;

            .form-control {
                background: transparent;
            }

            label {
                color : #000000;
                font-size: 16px;
                font-weight: 600;
            }

            &.active{
                border: 1px solid #4F4F4F;

                .accordion-header button{
                    background: #FFFFFF;
                    box-shadow: none;
                }
            }

            .accordion-collapse {
                background-color: #FFFFFF;
            }

            .accordion-header{
                background: #FFFFFF;
                
                button{
                    color: #000;
                    font-weight: bold;
                    padding: 16px;
                    height: 92px;
                    border-radius: 8px;

                    > * {
                        position: relative;
                        z-index: 1;
                        background: transparent;
                        color : #000000;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    img{
                        margin-right: 12px;
                    }

                    .edit-general-infos-btn{
                        font-size: 26px;
                        margin-left: 6px;
                        font-weight: normal;
                    }
                    
                }
            }

            .avatar-group{
                .avatar-group-item{
                    width: 40px;
                }
            }
        }
    }

    .products-list{
        padding: 0px 16px 0px 16px;
        border: 1px solid #899BA1;
        border-radius: 12px;

        input[type="text"]{
            padding: 9px 0px 9px 17px;
        }

        button{
            padding: 7px 14px 7px 14px;
        }
    }

    .contact-people{
        padding: 32px;
        display: flex;
        justify-content: center;
        align-items: end;
        flex-flow: column;

        .list{
            width: 100%;
            
            .item{
                border: 1px solid #828282;
                border-radius: 12px;
                padding: 16px;
            }
        }
    }

    .findings-list{    
        font-size: 14px;
        color: #282828;
        .finding-list-item{
            position: relative;
            background-color: #fff;
            padding: 16px 30px 16px 30px;
            border-radius: 12px;

            .finding-list-item-checkbox{
                position: absolute;
                left: 6px;
                top: 6px;
            }

            .finging-container{

                .image-container{
                    img{
                        max-width: 270px;
                        border-radius: 14px;
                    }
                }

                .finding-content{
                    .finding-content-row{
                        > div.desc{
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 4;
                            line-clamp: 4;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .flag-btn{
                            background-color: transparent;
                            border: none;
                            padding: 0px;
                            margin: 0px;
                        }
                        .category-title{
                            font-size: 12px;
                            color: #4F4F4F;
                            font-weight: 400;
                        }

                        .finding-title{
                            font-size: 14px;
                            font-weight: 600;
                            color:#1A1D30;
                            cursor: pointer;
                        }

                        .link{
                            font-size: 10px;
                            color: #4F4F4F;
                            text-decoration: underline !important;
                        }

                        .time{
                            color: #828282;
                            font-size: 12px;
                        }

                        .relevancy-score{
                            font-size: 12px;
                            font-weight: bold;
                            
                            .score-value{
                                margin-left: 4px;
                                
                                &.danger{
                                    color: #DC3545;
                                }
                                &.warning{
                                    color: #FCC34B;
                                }
                                &.success{
                                    color: #1CBB8C;
                                }
                            }
                        }
                    }
                }
            }

            &:hover{
                background-color: #CBD1FC80;
            }
        }
    }

    .report-statistics-card{
        font-size: 12px;
        font-weight: 600;
        box-shadow: 0px 4px 4px 0px #00000040;

        h4{
            font-size: 16px;
        }
        img{
            width: 38px;
        }
    }

    .accordion{
        &.questionnaire{
            .accordion-item{
                &.active{
                    border: none;
                    .accordion-header{
                        button{
                            background: #F5F6FF;
                        }
                    }
                }

                .accordion-header{
                    background-color: #F5F6FF;

                    button{
                        height: 64px;

                        img{
                            width: 24px;
                        }
                    }
                }

                .accordion-collapse {
                    background-color: #F5F6FF;
                }

                .accordion-body{
                    padding: 8px 42px 8px 42px;

                    .sub-section{
                        margin-left: 15px;
                        .sub-section-title{
                            font-weight: 500;
                        }

                        .sub-section-body{
                            margin-left: 15px;
                        }
                    }

                    .question{
                        .question-title{
                            font-weight: 400;
                        }

                        > p{
                            margin-left: 8px;
                        }
                    }

                    .sub-question-container{
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        flex-direction: row;
                        margin-top: 12px;
                        padding: 0px 0px;
                        margin-left: 15px;
                        margin-bottom: 15px;

                        .question{
                            flex-basis: 50% !important;
                            flex: 2 1;
                        }
                    }

                    .files-container{
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        flex-direction: row;

                        .file-preview{
                            display: flex;
                            flex-flow: column;
                            justify-content: center;
                            align-items: start;
                            flex-basis: 33.33%;
                            flex: 2 1;
                        }
                    }
                }
            }
        }
    }

    .key-words{
        .badge{
            background-color: #F8F9FA;
            color: #212529;
            border: 1px solid #CED4DA;
            font-weight: 400;
        }
    }
}

.ai-risk-result-modal{
    .assessment-title{
        display: none;
    }
}

.history-log-details-modal{
    .detail-label,
    .detail-value{
        color: #8A8DA3;
        font-weight: 500;
        font-size: 14px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .detail-value{
        color: #1A2052;

        i{
            font-size: 20px;
        }

        &.bold{
            font-weight: 700;
        }

        &.light{
            color: #888AAB;
        }
    }
}

.finding-details-content{
    .side-box{
        border-radius: 20px;
        border: 1px solid #5664D2;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        max-height: 220px;

        @media (max-width: 992px){
            padding: 8px;
            height: 100%;
            justify-content: space-around;
        }

        &.ai-help-box{
            .image-box{
                img{
                    max-width: 120px;
                }
            }

            button{
                img{
                    max-width: 20px;
                }
            }
        }

        &.relevancy-score{
            .score-progress-container{
                display: flex;
                justify-content: center;
                align-items: center;

                .progress{
                    height: 15px;
                    border-radius: 15px;
                    width: 100%;

                    .progress-bar{
                        height: 15px;
                    }

                    &.danger .progress-bar{
                        background-color: #DC3545 !important;
                    }
                    &.warning .progress-bar{
                        background-color: #FCC34B !important;
                    }
                    &.success .progress-bar{
                        background-color: #1CBB8C !important;
                    }
                }
                .percent{
                    margin-left: 10px;
                    font-size: 10px;
                }
            }
        }
    }

    .finding-content{
        .sanction-table-container{
            border: 1.35px solid #1A1D3033;
            border-radius: 10px;
            overflow: hidden;

            tbody{
                td,th{
                    padding: 20px 94px 20px 63px;
                }
            }
        }

        .finding-content-title{
            display: flex;
            justify-content: space-between;
            align-items: start;

            h5{
                margin: 0px;
                font-size: 18px;
                font-weight: 700;
                color : #000000;
            }
        }

        .finding-actions-container {
            display: flex;
            justify-content: end;
            align-items: center;
        }

        .finding-content-image {
            overflow: hidden;
            img{
                border-radius: 12px;
                max-width: 580px;
            }

            @media (max-width: 992px){
                img {
                    max-width: 100%;
                }
            }
        }

        .finding-content-body{
            line-height: 21px;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
        }

        .finding-source-link{
            a{
                color: #4F4F4F;
                font-size: 10px;
                font-weight: 400;
            }
        }
    }
}

.overview-content{
    .overview-section-title{
        font-weight: 600;
        font-size: 16px;
        color: #1D1D1D;
    }

    .latest-finding-section{
        .card{
            border: 1px solid #BDBDBD;
            border-radius: 12px;
            padding: 16px 30px 16px 30px;

            img{
                border-radius: 14px;
            }

            .card-body{
                padding-left: 0px;
                padding-right: 0px;
                font-size: 14px;
                color: #1A1D30CC;
                font-weight: 400;

                .card-title{
                    font-weight: 600;
                    color: #000000;
                    min-height: 70px;
                    cursor: pointer;
                }

                small{
                    font-size: 12px;
                    &.category{
                        color: #4F4F4F;
                    }
                    &.last-update{
                        color: #828282;
                    }
                }
            }
        }
    }

    .message-details-box{
        border: none;

        h5{
            font-size: 16px !important;
            color: #1D1D1D;
        }
    }
}

.link-qr-code-modal{
    .modal-title{
        justify-content: center;
        text-align: center;
        font-size: 18px;
        color: #333333;
        font-weight: 700;
    }
}

.keyboard-modal-main-content{
    .modal-header{
        padding: 2rem 2rem 0.5rem 2rem;
        border: none;
        button{
            right: 1.25rem;
            top: 1rem;
            font-size: 12px;
        }

    }
    .modal-body{
        padding: 0rem 2rem 0.5rem 2rem;
        border: none;
        .selected-input {
            border-radius: 0.375rem;
            border: 1px solid #DEE2E6;
            div{
            border:none;
            }
        }
    }

    .modal-footer{
        padding: 1rem 2rem 2rem 2rem;
        border: none;
        button{
            border-radius: 0.375rem;
            margin: 0;
        }
    }

    .modal-content{
        width: 648px;
    }
}

.manage-keywords-container {
    min-height: 100px;
    border: 1px solid #DEE2E6;
    padding: 0.375rem ;
    border-radius: 0.375rem;
    overflow: auto;
    margin-top: 0.5rem;
    background-color: #fff; // Adding background color to resemble a textarea
    &:focus {
      outline: none; // Removes the outline on focus to mimic a textarea
    }
}
  
.tag {
    display: inline-block;
    background-color: #e9ecef;
    border-radius: 4px;
    padding: 3px 6px 3px 6px;
    margin: 2px 4px;
    font-size: 14px;
    background-color: rgba(248, 249, 250, 1);
    cursor: pointer;
    border: 1px solid rgba(206, 212, 218, 1);

    .tag-close {
        margin-left: 5px;
        cursor: pointer;
    }

    &:hover {
        background-color: darken(
        #e9ecef,
        5%
        ); // Darkens the tag background on hover
    }
}