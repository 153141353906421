.table-container {
    width: 100%;
    margin: auto;
  
    table {
      width: 100%;
      border-collapse: separate; /* Disable collapsing borders */
      border-spacing: 0; /* Remove spacing between cells */
      border: 1px rgba(137, 155, 161, 1) solid;
      border-radius: 12px;
      margin-bottom: 20px;
  
      th:first-child, td:first-child {
        border-top-left-radius: 12px; /* Round top-left of the first cell in each row */
        border-bottom-left-radius: 12px; /* Round bottom-left of the first cell in each row */
      }
  
      th:last-child, td:last-child {
        border-top-right-radius: 12px; /* Round top-right of the last cell in each row */
        border-bottom-right-radius: 12px; /* Round bottom-right of the last cell in each row */
      }
  
      th, td {
        text-align: left;
        padding: 8px;
      }
    }
  
    .edit-btn, .delete-btn {
      margin-right: 10px;
      cursor: pointer;
    }
}

.third-party-home-page {
    width: 100%;
    overflow: hidden;
    margin-top: 70px;
    height: auto;

    .background-image-container{
        width: 100%;
        margin: 0px;
        position: relative;
        top: -40px;

        img{
            width: 100%;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;

        .action-button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            margin: 0px 14px;

            img{
                max-width: 100%;
                max-height: 100%;
                margin-bottom: 14px;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .third-party-home-page{
        margin-top: 0px;
        .actions .action-button-container img{
            width: 140px;
        }
    }
}