.super-admin-add-user {
  .details-content-wrapper {
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #4f666e40;
  }
}

.user-management {
  .card-borderd {
    border: 1px solid $black;
  }
}
