.report-details-box{
    font-size: 16px;
    border: 1px solid #5664D2;
    padding: 16px 70px 16px 70px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;
    min-height: 295px;
    font-weight: 400;
    background-color: #5664D21A;
    background-image: url('../../../images/details-background.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1366px){
        padding: 16px 26px 16px 26px;
    }


    &.single-line-view {
        min-height: auto;
        max-height: 100PX;

        .report-details-row{
            grid-template-columns: repeat(3, minmax(min-content, max-content));

            &:last-child{
                display: none;
            }
        }
    }

    &.tp-view{
        min-height: auto;
        .report-details-row{
            grid-template-columns: repeat(6, minmax(min-content, max-content));

            &:last-child{
                display: none;
            }
        }
    }

    .report-details-row{
        display: grid;
        grid-template-columns: repeat(8, minmax(min-content, max-content));
        grid-template-rows: repeat(1, 132px);
        grid-gap: 12px 0px;
        gap: 12px 0px;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        justify-items: flex-start;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 1320px;
        

        .report-details-col{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            box-sizing: border-box;
            width: auto;
            height: auto;
            
            .detail-title{
                margin-bottom: 14px;
                font-weight: 600;
            }

            .detail-value{
                img{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                .supplier-flagging{
                    border: 0px;
                    text-align: left;
                    padding: 0px;
                    &:hover{
                        background-color: transparent;
                    }

                    img{
                        width: auto;
                        height: auto;
                        border-radius: 0px;
                    }

                    .info{
                        margin-top: 3px;
                        display: block;
                        font-size: 12px;
                        color: #4F4F4F;
                    }
                }
            }
        }

        &:first-child{
            .report-details-col{
                align-items: flex-start;
            }

            @media only screen and (max-width: 1366px){
                grid-template-columns: repeat(4, minmax(min-content, max-content));
                grid-template-rows: repeat(1, auto);
                margin-bottom: 32px;
            }

            @media only screen and (max-width: 992px){
                grid-template-columns: repeat(3, minmax(min-content, max-content));
            }

            @media only screen and (max-width: 480px){
                grid-template-columns: repeat(2, minmax(min-content, max-content));
            }
        }

        &:last-child{
            grid-template-columns: repeat(4, minmax(min-content, max-content));
            grid-template-rows: repeat(1, 132px);
            justify-content: center;
            align-items: flex-start;

            @media only screen and (max-width: 1366px){
                grid-template-rows: repeat(1, auto);
            }


            @media only screen and (max-width: 768px){
                grid-template-columns: repeat(2, minmax(min-content, max-content));
            }
        }
    }

    &.repeat-5{
        .report-details-row{
            grid-template-columns: repeat(5, minmax(min-content, max-content));
            grid-template-rows: repeat(1, 132px);

            @media only screen and (max-width: 992px){
                grid-template-columns: repeat(3, minmax(min-content, max-content));
            }

            @media only screen and (max-width: 480px){
                grid-template-columns: repeat(2, minmax(min-content, max-content));
            }
        }
    }

    &.single-line{
        min-height: auto;
        .report-details-row{
            margin-bottom: 0px;
            &:last-child{
                display: none;
            }
        }
    }
}

.loading-section{
    background-color: #eeeffa;
    border-radius: 8px;
}